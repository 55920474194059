/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2023 */



@font-face {
    font-family: 'work_sansitalic';
    src: url('worksans-italic-variablefont_wght.woff2') format('woff2'),
         url('worksans-italic-variablefont_wght.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'work_sansregular';
    src: url('worksans-variablefont_wght.woff2') format('woff2'),
         url('worksans-variablefont_wght.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}