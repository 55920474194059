/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2023 */



@font-face {
    font-family: 'staatlichesregular';
    src: url('staatliches-regular.woff2') format('woff2'),
        url('staatliches-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}