@import url('https://fonts.googleapis.com/css2?family=AR+One+Sans&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'AR One Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'AR One Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #011627 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.splide__slide img {
  width: 100%;
  height: auto;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide__slide {
  opacity: 0.6;
}


.splide__slide.is-active {
  opacity: 1;
}